import React, { useContext, useMemo } from 'react';
import { PageContext } from '../context/page-context';
import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';
import classNames from 'classnames';
import { DisplayRegion, getDisplayRegionCssClass } from 'components/utils/DisplayRegion';
import getAcfBlockProps from 'components/prop-types/acf-block';
import getEnumValidator from 'components/prop-types/enum';
import { getIsGDPR } from '../utils/gdpr';
import { toParagraphArray } from '../utils/to-paragraph-array';

const TitleStyle = {
	STANDARD_H1: 'standard-h1',
	JUMBO: 'jumbo',
} as const;
type TitleStyle = typeof TitleStyle[keyof typeof TitleStyle];

const titleStyleCssClass: Record<TitleStyle, string> = {
	[TitleStyle.STANDARD_H1]: 'hero-lead-capture__title--standard',
	[TitleStyle.JUMBO]: 'hero-lead-capture__title--jumbo',
};

const TextStyle = {
	LIGHT: 'light',
	DARK: 'dark',
} as const;
type TextStyle = typeof TextStyle[keyof typeof TextStyle];

const textStyleCssClass: Record<TextStyle, string> = {
	[TextStyle.LIGHT]: 'hero-lead-capture__content--light',
	[TextStyle.DARK]: 'hero-lead-capture__content--dark',
};

const propTypes = {
	...getAcfBlockProps(
		{
			region: getEnumValidator(DisplayRegion).isRequired,
			title: PropTypes.string,
			title_style: getEnumValidator(TitleStyle),

			text: PropTypes.string,
			text_style: getEnumValidator(TextStyle),
			terms_conditions: PropTypes.string,
		
			image_desktop: PropTypes.shape({
				url: PropTypes.string.isRequired,
				alt: PropTypes.string,
			}).isRequired,

			image_mobile: PropTypes.shape({
				url: PropTypes.string.isRequired,
				alt: PropTypes.string,
			}).isRequired,

			background_mobile: PropTypes.shape({
				url: PropTypes.string.isRequired,
			}),
			background_colour_mobile: PropTypes.string,
		}
	),
};
type HeroLeadCapturePropTypes = InferProps<typeof propTypes>;

const defaultProps = {
	title_style: TitleStyle.STANDARD_H1,
	text_style: TextStyle.DARK,
};

const HeroLeadCapture = Object.assign(
	function HeroLeadCapture(props: HeroLeadCapturePropTypes) {
		const content = props.block?.content;

		const profile = useContext(PageContext);

		const isGDPR = useMemo(getIsGDPR, [profile.geolocation]);

		const title_style = content?.title_style ?? defaultProps.title_style;
		const titleCssClass = useMemo(() => {
			if (title_style) {
				return titleStyleCssClass[title_style];
			}
		}, [title_style]);

		const text_style = content?.text_style ?? defaultProps.text_style;
		const textCssClass = useMemo(() => {
			if (text_style) {
				return textStyleCssClass[text_style];
			}
		}, [text_style]);

		if (!content) {
			return null;
		}

		const { region } = content;

		return (
			<div className={classNames(getDisplayRegionCssClass(region))}>

				{
				/* `content` should always exist, and would only be falsy if something's gone wrong in the CMS */
				content &&
				<>
					<div className='hero-lead-capture__content-container'>
						<div className={classNames('hero-lead-capture__content', textCssClass)}>
							{
								(content.background_mobile?.url || content.background_colour_mobile) &&
								<div
									className="hero-lead-capture__background-mobile"
									style={{
										backgroundColor: content.background_colour_mobile || undefined,
										backgroundImage: content.background_mobile?.url ? `url(${content.background_mobile?.url})` : undefined,
									}}
								></div>
							}

							<div className="container">
								<div className="hero-lead-capture__container">
									{
										content.title &&
										<h1 className={classNames('hero-lead-capture__title', titleCssClass)}>{content.title}</h1>
									}
									{
										content.text &&
										<div className="hero-lead-capture__text">
											{toParagraphArray(content.text).map((paragraph, i) => <p key={i}>{paragraph}</p>)}
										</div>
									}

									<form
										className="hero-lead-capture__form"
										action="https://app.gettimely.com/register"
									>
										<div className="hero-lead-capture__form__row">
											<input type="email" className="Input hero-lead-capture__form__input" name="email" placeholder="Enter email address" required />
											<button type="submit" className="Button Button--primary hero-lead-capture__form__submit">Start free trial</button>
										</div>

									{content.terms_conditions ? (
										<div className="hero-lead-capture__form__privacy" dangerouslySetInnerHTML={{ __html: content.terms_conditions }}></div>
									) : (
										<>
											{isGDPR === true && (
												<p className="hero-lead-capture__form__privacy">
													<label>
														<input type="checkbox" name="privacy" required />
														<span>I agree to the <a href="/support/privacy" target="_blank" className="no-color-override">privacy policy</a></span>
													</label>
												</p>
											)}
											{isGDPR === false && (
												<p className="hero-lead-capture__form__privacy">See our <a href="/support/privacy" target="_blank" className="no-color-override">privacy policy</a></p>
											)}
											{isGDPR === null && (
												<p className="hero-lead-capture__form__privacy"></p>
											)}
										</>
									)}

									</form>
								</div>
							</div>
						</div>

						{
							content.image_desktop.url &&
							<img
								className="hero-lead-capture__image-desktop"
								src={content.image_desktop.url}
								alt={content.image_desktop.alt || ''}
							/>
						}
						{
							content.image_mobile.url &&
							<img
								className="hero-lead-capture__image-mobile"
								src={content.image_mobile.url}
								alt={content.image_mobile.alt || ''}
							/>
						}
					</div>
				</>
				}
			</div>
		);
	},

	{
		propTypes,
		defaultProps,
	},
);

export default HeroLeadCapture;
