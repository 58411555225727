/**
 * Split a string that may contain line breaks into an array
 * of paragraphs based on where those line breaks are.
 *
 * This function is intended for use on text input from the CMS.
 */
export function toParagraphArray(text: string | null | undefined): string[] {
	const textArr = (text?.split(/\s*\n\s*/g) ?? []);

	return textArr;
}
